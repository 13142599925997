define("discourse/plugins/discourse-bbb/discourse-bbb/controllers/insert-bbb", ["exports", "@ember/controller", "discourse/mixins/modal-functionality", "discourse-common/utils/decorators", "@ember/utils"], function (_exports, _controller, _modalFunctionality, _decorators, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _controller.default.extend(_modalFunctionality.default, (_dec = (0, _decorators.default)("meetingID"), (_obj = {
    keyDown(e) {
      if (e.keyCode === 13) {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }
    },
    onShow() {
      this.setProperties({
        meetingID: "",
        attendeePW: "",
        moderatorPW: "",
        buttonText: "",
        mobileIframe: false,
        desktopIframe: true
      });
    },
    randomID() {
      return Math.random().toString(36).slice(-8);
    },
    insertDisabled(meetingID) {
      return (0, _utils.isEmpty)(meetingID);
    },
    actions: {
      insert() {
        const btnTxt = this.buttonText ? ` label="${this.buttonText}"` : "";
        this.toolbarEvent.addText(`[wrap=discourse-bbb meetingID="${this.meetingID}"${btnTxt} attendeePW="${this.randomID()}" moderatorPW="${this.randomID()}" mobileIframe="${this.mobileIframe}" desktopIframe="${this.desktopIframe}"][/wrap]`);
        this.send("closeModal");
      },
      cancel() {
        this.send("closeModal");
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "insertDisabled", [_dec], Object.getOwnPropertyDescriptor(_obj, "insertDisabled"), _obj)), _obj)));
});